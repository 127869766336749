<template>
  <div></div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
export default {
  methods: {
    ...mapActions({
      loadProducers: 'commissions/producers/loadProducers',
    }),
  },
  mounted() {
    // load producers list
    this.loadProducers({
      date: this.date,
    }).then(() => {
      this.$router.push({
        name: 'commission-statement.producer',
        params: {
          year: this.$route.params.year,
          month: this.$route.params.month,
          producer: this.producers[0].id,
        },
      })
    })
  },
  computed: {
    ...mapGetters({
      producers: 'commissions/producers/getProducers',
    }),
    date() {
      return `${this.$route.params.year}-${this.$route.params.month}`
    },
  },
}
</script>

<style></style>
